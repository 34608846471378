import React from "react";
import clamMint from "../image2/clam-mint-ai.png"
import beefSteak from "../image2/beef-lu-lac-ai.png"
import saltAndPepperShrimp from "../image2/salt-pepper-shrimp-ai.png"
import walnutShrimp from "../image2/walnut-shrimp-ai.png"
import houseFishFilet from "../image2/house-fish-filet-ai.png"
import friedTofu from "../image2/fried-tofu-ai.png"
import stringbeachGarlic from "../image2/stringbean-garlic-sauce-ai.png"
import sauteedOngchoy from "../image2/ong-choy-ai.png"
import kungPaoChicken from "../image2/kung-pao-chicken-ai.png"
import houseSpecialLobster from "../image2/house-special-lobster.png"
import mongolianBeef from "../image2/mongolianbeef-ai.png"
import beefChowFun from "../image2/beef-chow-fun-ai.png"



const Menus = () => {
  // services details
  const menus = [
    {
      name: "House Special Lobster",
      desc: "Our House Special Lobster is freshly chopped, deep fried and tossed over our house special sauce with scallions.",
      image: houseSpecialLobster,
    },
    {
      name: "Clams with Mint Sauce",
      desc: "Fresh clams steamed and stir fryed in our signature mint sauce.",
      image: clamMint,
    },
    {
      name: "French Style Beef Steak",
      desc: "Filet Mignon diced into cubes and stir fryed with our special sauce.",
      image: beefSteak,
    },
    {
      name: "Salt & Pepper Shrimp",
      desc: "Deep fried shrimp tossed in salt and pepper with green onions.",
      image: saltAndPepperShrimp,
    },
    {
      name: "Walnut Shrimp",
      desc: "Breaded shrimp tossed in our signature mayo topped with sweetend walnuts.",
      image: walnutShrimp,
    },
    {
      name: "House Special Fish Filet",
      desc: "Breaded swai filet tossed in our house special sauce.",
      image: houseFishFilet,
    },
    {
        name: "Fried Tofu",
        desc: "Battered tofu deep fried served with soy sauce & sweet and sour sauce.",
        image: friedTofu,
      },
      {
        name: "String Bean Garlic Sauce",
        desc: "Stir fried green beans with minced pork tossed in our garlic sauce.",
        image: stringbeachGarlic,
      },
        {
      name: "Sauteed Ong Choy",
      desc: "Ong Choy stir fried with garlic.",
      image: sauteedOngchoy,
    },
    {
      name: "Kung Pao Chicken",
      desc: "Dark meat chicken stir fried with peanuts, peppers, and green onions.",
      image: kungPaoChicken,
    },
    {
      name: "Mongolian Beef",
      desc: "Thinly sliced tender beef seared to perfection, glazed in a savory-sweet soy sauce.",
      image: mongolianBeef,
    },
      {
    name: "Beef Chow Fun",
    desc: "Rice noodle stir fried with beef, egg, scallion, and beansprouts.",
    image: beefChowFun,
  },
  ];

  return (
    <section id="menus" className="section bg-light">
      <div className="container">
        {/* Heading */}
        <p className=" text-center mb-2 wow fadeInUp">
          <span className="bg-primary text-dark px-2">Our Menu</span>
        </p>
        <h2 className="text-10 fw-600 text-center mb-5 wow fadeInUp">
          You won't regret your decisions!
        </h2>
        {/* Heading end*/}
        <div className="row gy-5 mt-5">
        {menus.length > 0 &&
  menus.map((menu, index) => (
    <div className="col-sm-6 col-lg-4 wow fadeInUp" key={index}>
      <div className="featured-box text-center px-md-4">
        <div className="featured-box-icon text-primary text-13">
          {" "}
          {menu.image ? (
            <img src={menu.image} alt={menu.name} className="service-image" />
          ) : (
            <i className={menu.icon} />
          )}
        </div>
        <h3 className="text-6 fw-600 mb-3">{menu.name}</h3>
        <p className="text-muted mb-0">{menu.desc} </p>
      </div>
    </div>
))}
        </div>
      </div>
    </section>
  );
};

export default Menus;
