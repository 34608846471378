import React from "react";

import Slider from "react-slick";

const Testimonials = () => {
  const reviews = [
    {
      name: "Dianne C",
      position: "Elite Yelper",
      src: "images/yelpsmall.png",
      desc: "“Love special fish filet here! So tasty and flavorful! This place is a hidden gem! I always get the snow pea tip fish fillet and pork chop! All the staff here are extremely welcoming and very polite! Which is a rarity at most Chinese spots! Hidden gem don't get fooled by the way it looks! Good quality food and GREAT GREAT Value fish pork chop greens fried rice chow mein 98 bucks.”",
    },
    {
      name: "Jonathan N",
      position: "Elite Yelper",
      src: "images/yelpsmall.png",
      desc: " “This location recently underwent new management and their older location closed down. Regardless, the food and environment is still perfect if you want a casual, inexpensive, but tasty lunch with a large group of people.There's never a wait when we go. The inside looks a lot cleaner and new now. All dishes are served family style. My family always orders the following because every dish is always solid: green beans, pork chops, fried shrimp, fried calamari, and fish filet. If you have a craving for Chinese food, this is the way to go.”",
    },
    {
      name: "Lindsey D.",
      position: "Elite Yelper",
      src: "images/yelpsmall.png",
      desc: "“The food is still great and the service is still great as well! I tried a lot of food and my all time favorite dishes that are a must are the kung pao chicken (crispy and juicy), the house special squid (crispy and salty has the best seasonings ever), and the pea sprouts (flavorful and light easy to eat).”",
    },
    {
      name: "CHERYL N",
      position: "Elite Yelper",
      src: "images/yelpsmall.png",
      desc: "“We had $17 hot and sour soup with pork, it was so good n such a big bowl, we each had 3 cups n there was still done left. The seafood chow mein was so flavorful, choose the one w gravy it came w more vegetables n shrimp. The garlic pork eggplant was so good I even licked the plate! Just kidding. I ate so much I must have gained 3 lbs n felt totally satisfied after a few bad experiences, read my next review. I'll come baaaaccck for the lunch menu only $12.95 great prices.”",
    },
    {
      name: "Elizabeth S",
      position: "Elite Yelper",
      src: "images/yelpsmall.png",
      desc: "“I believe they changed ownership but it's a good one. The place looks a lot nicer and cleaner. The tables and chairs are placed very neatly. The menu has so many options that my family ended up getting the house special calamari, fried fish, fried rice, basil clams, and some kind of stir fried vegetable. Overall it's a lot cheaper than seafood cove 2 and I can't wait to try more of their menu selection”",
    },
  ];

  const SlickArrowLeft = ({ currentSlide, slideCount, ...props }) => (
    <button
      {...props}
      className={
        "slick-prev slick-arrow" + (currentSlide === 0 ? " slick-disabled" : "")
      }
      aria-hidden="true"
      aria-disabled={currentSlide === 0 ? true : false}
      type="button"
    >
      <i className="fa fa-chevron-left"></i>
    </button>
  );
  const SlickArrowRight = ({ currentSlide, slideCount, ...props }) => (
    <button
      {...props}
      className={
        "slick-next slick-arrow" +
        (currentSlide === slideCount - 1 ? " slick-disabled" : "")
      }
      aria-hidden="true"
      aria-disabled={currentSlide === slideCount - 1 ? true : false}
      type="button"
    >
      <i className="fa fa-chevron-right"></i>
    </button>
  );

  var settings = {
    dots: true,
    arrows: true,
    prevArrow: <SlickArrowLeft />,
    nextArrow: <SlickArrowRight />,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4500,
  };

  return (
    <section id="testimonial" className="section bg-secondary">
      <div className="container">
        {/* Heading */}
        <p className=" text-center mb-2 wow fadeIn">
          <span className="bg-primary text-dark px-2">Customer Testimonials</span>
        </p>
        <h2 className="text-10 fw-600 text-white text-center mb-5 wow fadeIn">
          What Some of Our Customers Say
        </h2>
        {/* Heading end*/}
        <div className="row">
          <div className="col-lg-9 mx-auto wow fadeInUp">
            <Slider {...settings}>
              {reviews.length > 0 &&
                reviews.map((review, index) => (
                  <div className="item text-center px-5" key={index}>
                    {" "}
                    <span className="text-9 text-primary">
                      <i className="fa fa-quote-start" />
                    </span>
                    <p className="text-5 text-white">{review.desc}</p>
                    <img
                      className="img-fluid d-inline-block w-auto rounded-circle shadow"
                      src={review.src}
                      alt={review.name}
                    />{" "}
                    <strong className="d-block text-3 fw-600 text-white">
                      {review.name}
                    </strong>{" "}
                    <span className="text-light">{review.position}</span>{" "}
                  </div>
                ))}
            </Slider>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Testimonials;
