import React from "react";


import "react-toastify/dist/ReactToastify.css";
import { Tooltip } from "./Tooltip";

const Contact = () => {
 

  return (
    <section id="contact" className="section bg-primary">
      <div className="container">
        <div className="row">
        <div
  className="col-lg-5 text-center align-self-center mb-4 mb-lg-0 order-0 order-lg-1"
  style={{ marginLeft: '100px' }}
>
  <div className="bg-light rounded-pill d-inline-block p-3 shadow-lg wow zoomIn">
    <img
      className="img-fluid contact-pic rounded-pill d-block"
      src="images/clam-mint-ai.png"
      alt="clams"
      style={{ width: '500px', height: '500px' }}
    />
  </div>
</div>
          <div className="col-lg-5 text-center text-lg-start wow fadeInUp">
            <h2 className="text-10 fw-600 mb-5">Come dine with us!</h2>
            <p className="text-5 mb-5">
              We can't wait to have you here at Capital Seafood Garden Grove!
              Our delicious dishes are waiting for you. You won't regret stopping by!
            </p>
            <h3 className="text-5 fw-600">Location:</h3>
            <address className="text-4">
              8851 Westminster Blvd, Garden Grove, CA 92844
            </address>
            <h3 className="text-5 fw-600">Call:</h3>
            <p className="text-4">(+714) 892 4182</p>
            <h3 className="text-5 fw-600">Hours</h3>
            <p className="text-4">Mon - Sun 11am - 9pm</p>
            <p className="text-4">CLOSED ON TUESDAY!</p>

            <ul className="social-icons social-icons-lg justify-content-center justify-content-lg-start mt-5">
              <li className="social-icons-twitter">
                <Tooltip text="Twitter" placement="top">
                  <a
                    href="https://twitter.com/capitalseafoodgg/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="fab fa-twitter" />
                  </a>
                </Tooltip>
              </li>
              <li className="social-icons-facebook">
                <Tooltip text="Facebook" placement="top">
                  <a
                    href="http://www.facebook.com/capitalseafoodgardengrove/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="fab fa-facebook" />
                  </a>
                </Tooltip>
              </li>
              <li className="social-icons-instagram">
                <Tooltip text="Instagram" placement="top">
                  <a
                    href="http://www.instagram.com/capitalseafoodgg"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="fab fa-instagram" />
                  </a>
                </Tooltip>
              </li>
              {/* <li className="social-icons-github">
                <Tooltip text="Github" placement="top">
                  <a
                    href="http://www.github.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="fab fa-github" />
                  </a>
                </Tooltip>
              </li>
              <li className="social-icons-dribbble">
                <Tooltip text="Dribbble" placement="top">
                  <a
                    href="http://www.dribbble.com/harnishdesign/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="fab fa-dribbble" />
                  </a>
                </Tooltip>
              </li> */}
            </ul>
          </div>
          </div>
          </div>
          
      
    </section>
    
  );
};

export default Contact;
